<template>
  <div id="changePasswordComponent">
    <CCard>
      <CCardHeader>
        <b>{{ title }}</b>
      </CCardHeader>
      <CCardBody>
        <CCol md="6">
          <CForm>
            <CInput
              type="password"
              label="New Password"
              placeholder="Enter new password..."
              autocomplete="new-password"
              v-model="inputPassword"
              description="Must be 6-20 characters long."
              :is-valid="validator"
              required
            />
            <CInput
              type="password"
              label="Confirm Password"
              placeholder="Enter confirm password..."
              autocomplete="new-password"
              v-model="inputConfirmPassword"
              invalid-feedback="Not match with new password"
              :is-valid="validatorConfirmPassword"
              required
            />
          </CForm>
        </CCol>
      </CCardBody>
      <CCardFooter>
        <CCol md="6">
          <CButton
            type="submit"
            size="sm"
            color="primary"
            @click="onChangePassword"
          >
            <CIcon name="cil-check-circle" /> Save
          </CButton>
        </CCol>
      </CCardFooter>
    </CCard>
  </div>
</template>

<script>
import AuthProxy from '@/proxies/auth.proxy';

export default {
  props: {
    customTitle: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      title: this.customTitle,
      inputPassword: '',
      inputConfirmPassword: '',
    };
  },
  methods: {
    validator(val) {
      return val ? val.length >= 6 : false;
    },
    validatorConfirmPassword(val) {
      return val ? val === this.inputPassword : false;
    },
    onChangePassword() {
      if (
        this.inputPassword === this.inputConfirmPassword &&
        this.inputPassword.length >= 6
      ) {
        AuthProxy.changePassword({ new_password: this.inputPassword }).then(
          () => {
            this.$toast.success(`Change password successfully`, {
              duration: 5000,
            });
          }
        );
      }
    },
  },
  name: 'ChangePasswordComponent',
};
</script>
