<template>
  <div id="setup2FAComponent">
    <CCard>
      <CCardHeader>
        <b>{{ title }}</b>
      </CCardHeader>
      <CCardBody>
        <CCol>
          <div class="align-middle">
            <b>2FA Authentication &#160; &#160;</b>
            <CSwitch
              size="sm"
              labelOn="On"
              labelOff="Off"
              shape="pill"
              color="success"
              @change.native="onChange2FA(twoFactor.isEnabled)"
              :checked.sync="twoFactor.isEnabled"
            />
          </div>
        </CCol>
      </CCardBody>
    </CCard>
    <Enter2FAModal
      :showModal="isShowVerify2FAModal"
      @close-modal="closeModal"
      @2fa-verify-code="turnOff2FA"
    />
    <Setup2FAModal
      :showModal="isShowSetup2FAModal"
      :twoFactor="twoFactor"
      @close-modal="closeModal"
      @2fa-verify-code="turnOn2FA"
    />
  </div>
</template>

<script>
import UserInternalProxy from '@/proxies/userinternal.proxy';
import { mapGetters } from 'vuex';
import { ACTION_FETCH_PROFILE } from '@/store/actions.type';
import Enter2FAModal from '@/components/modals/Enter2FAModal';
import Setup2FAModal from '@/components/modals/Setup2FAModal';

export default {
  components: {
    Enter2FAModal,
    Setup2FAModal,
  },
  props: {
    customTitle: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      title: this.customTitle,
      twoFactor: {
        isEnabled: false,
        qrcode: null,
        secret: '',
      },
      isShowVerify2FAModal: false,
      isShowSetup2FAModal: false,
    };
  },
  mounted() {
    this.twoFactor.isEnabled = this.profile.two_factor_enabled;
  },
  computed: {
    ...mapGetters(['profile']),
  },
  methods: {
    onChange2FA(val) {
      if (val) {
        this.isShowSetup2FAModal = true;

        UserInternalProxy.generate2FA().then((res) => {
          this.twoFactor.qrcode = res.data.qrcode;
          this.twoFactor.secret = res.data.secret;
        });
      } else {
        this.isShowVerify2FAModal = true;
      }
    },
    turnOn2FA({ code }) {
      if (!code) return;
      let data = { code: code, enabled: true };

      UserInternalProxy.setup2FA(data).then(() => {
        this.isShowSetup2FAModal = false;

        this.$store.dispatch(ACTION_FETCH_PROFILE);
        this.$toast.success(`Turn on 2FA successfully`, {
          duration: 5000,
        });
      });
    },
    turnOff2FA({ code }) {
      if (!code) return;
      let data = { code: code, enabled: false };
      UserInternalProxy.setup2FA(data).then(() => {
        this.isShowVerify2FAModal = false;

        this.$store.dispatch(ACTION_FETCH_PROFILE);
        this.$toast.success(`Turn off 2FA successfully`, {
          duration: 5000,
        });
      });
    },
    closeModal() {
      this.twoFactor.isEnabled = this.profile.two_factor_enabled;
      this.isShowVerify2FAModal = false;
      this.isShowSetup2FAModal = false;
    },
  },
  name: 'Setup2FAComponent',
};
</script>
