<template>
  <div id="enter2FAModal">
    <CModal
      :show.sync="showModal"
      size="sm"
      :centered="true"
      :closeOnBackdrop="false"
    >
      <CForm>
        <CInput
          maxlength="6"
          type="text"
          label="Enter 2FA code"
          placeholder="Code..."
          v-model="code"
          required
          :is-valid="validator"
        />
      </CForm>
      <template #header>
        <h5 class="modal-title">Verify 2FA</h5>
        <CButtonClose @click="closeModal" />
      </template>
      <template #footer>
        <CButton
          @click="$emit('2fa-verify-code', { code })"
          color="primary"
          :disabled="!validator(code)"
          >Verify</CButton
        >
      </template>
    </CModal>
  </div>
</template>

<script>
export default {
  props: {
    showModal: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      code: '',
    };
  },
  methods: {
    validator(val) {
      return val ? val.length == 6 : false;
    },
    closeModal() {
      this.code = '';
      this.$emit('close-modal');
    },
  },
  name: 'Enter2FAModal',
};
</script>
