<template>
  <div id="setup2FAModal">
    <CModal
      :show.sync="showModal"
      size="lg"
      :centered="true"
      :closeOnBackdrop="false"
    >
      <CForm>
        <p class="my-2">
          Scan the QR code or enter the secret key in Google Authenticator
        </p>

        <CImg
          v-bind:src="twoFactor.qrcode"
          width="200px"
          height="200px"
          class="mb-2"
          shape="rounded"
        />
        <p>
          Secret Key - <b>{{ twoFactor.secret }}</b>
        </p>

        <CInput
          class="w-50"
          maxlength="6"
          type="text"
          label="Confirm Auth code"
          placeholder="Code..."
          v-model="code"
          required
          :is-valid="validator"
        />
      </CForm>
      <template #header>
        <h5 class="modal-title">Turn On 2FA</h5>
        <CButtonClose @click="closeModal" />
      </template>
      <template #footer>
        <CButton
          @click="$emit('2fa-verify-code', { code })"
          color="primary"
          :disabled="!validator(code)"
          >Save</CButton
        >
      </template>
    </CModal>
  </div>
</template>

<script>
export default {
  props: {
    showModal: {
      type: Boolean,
      required: true,
      default: false,
    },
    twoFactor: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      code: '',
    };
  },
  methods: {
    validator(val) {
      return val ? val.length == 6 : false;
    },
    closeModal() {
      this.$emit('close-modal');
    },
  },
  name: 'Setup2FAModal',
};
</script>
